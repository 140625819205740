.whitelist-slideshow-page {
  $modal-width: 500px;
  $max-modal-content-width: 404px;
  font-family: "Source Sans Pro", sans-serif;
    
  .backdrop {
    background-color: #303133;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }
    
  .content-wrapper {
    background-color: #fff;
    border-radius: 8px;
    width: $modal-width;
    z-index: 101;
    left: 50%;
    top: 50%;
    margin-left: calc(-#{$modal-width} / 2);
    position: fixed;
    transform: translateY(-50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo-container {
      padding-top: 24px;
    }

    h2 {
      color: #16171B;
      font-size: 28px;
      font-weight: 600;
      margin: 12px 0;
      max-width: $max-modal-content-width;
    }

    .top-section {
      max-width: $max-modal-content-width;

      p {
        color: #434A67;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }

      .modal-actions {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
  
        button {
          border-radius: 4px;
          font-weight: 600;
          font-size: 18px;
          height: 48px;
        }

        button.whitelist-modal-non-assertive {
          background-color: transparent;
          border: none;
          color: #027EB0;
          text-align: center;
        }
      }
    }

    .horizontal-divider {
      border: 1px solid #E3E6EF;
      width: 100%;
    }

    .bottom-section {
      padding: 24px 0;

      .whitelist-modal-additional-action {
        color: #636C8B;
        font-size: 14px;

        .highlight-non-assertive-action {
          background-color: transparent;
          border: none;
          color: #636C8B;
          font-weight: 600;
          padding: 0;
          margin: 0;
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (max-width: 928px) {
  .whitelist-slideshow-page {
    .content-wrapper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      bottom: 0;
      left: 0;
      top: unset;
      margin-left: unset;
      transform: none;
      width: unset;

      h2 {
        color: #000000;
        font-size: 26px;
        font-weight: 600;
      }

      .top-section {
        margin: 0 24px;

        p {
          color: #434A67;
          font-weight: 400;
          font-size: 20px;
          text-align: justify;
        }

        .modal-actions {
        }
      }

      .bottom-section {
        .whitelist-modal-additional-action {
          font-size: 16px;
        }
      }
    }
  }
}
